import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import './index.css'

function ExportData({handleDownload,...prop}) {
  // const MessageResultFalse =({message})=>{
  //   return <div style={{
  //     display: message!==''?'block':'none',
  //     color:'red',
  //     fontSize:'12px' 
  //   }}>
  //     {message}
  //   </div>
  // }
  return (
    <div>
      <Button type="primary" className='btn-export' onClick={handleDownload} icon={<DownloadOutlined />}>
         Export Data
      </Button>
      {/* <MessageResultFalse message={message}/> */}
    </div>
  );
}

export default ExportData;
