const API_ENDPOINT = {
  LOGIN: "/auth/login",
  REGISTER: "/auth/signup",
  RESET_PASSWORD: "/auth/reset",
  CHANGE_PASSWORD: "/auth/change",
  SEND_OTP: "/auth/sendOtp",
  FORGOT_USER_PASSWORD: "/auth/forgot",
  LOGIN_BY_OTP: "/auth/login-totp",
  LOGIN_GOOGLE: "/auth/login-google",
  VERIFY_LOGIN_BY_OTP: "/auth/verify-totp",
  GET_WORKSPACE: "/workspace",
  GET_WORKSPACE_BY_ADMIN: "/workspace/list/admin",
  GET_USER: "/user",
  CANCEL_WORKSPACE: "/workspace/cancel",
  RESTART_WORKSPACE: "/workspace/restart",
  DELETE_WORKSPACE: "/workspace/delete",
  SAVE_WORKSPACE_SETTING: "/setting/list/update",
  GET_WORKSPACE_SETTING: "/setting",
  DELETE_WORKSPACE_SETTING: "/setting/delete",
  CHANGE_PLAN_WORKSPACE: "/workspace/plan",
  CHANGE_STATUS_WORKSPACE: "/workspace/status",
  GET_USER_BY_TOKEN :'/auth/verify',
};
export default API_ENDPOINT;
