export const SCREEN_NAME = {
  LOGIN: '/login',
  REGISTER_SAAS: '/register-saas',
  SUCCESS_REGISTER_SAAS: '/success-register-saas',
  WORKSPACES: '/workspaces',
  USERS: '/users',
  WORKSPACE_DETAILS_ID: '/workspace-details/:id',
  WORKSPACE_DETAILS: '/workspace-details',
  PROFILE: '/profile',
  HOME: '/',
  USER_DETAIL_ID: '/user-detail/:id',
  USER_DETAIL: '/user-detail',
  FORGOT_PASSWORD: '/forgot-password',
  INPUT_OTP: '/input-otp',
  NEW_PASSWORD: '/new-password',
  WORKSPACE_SETTINGS: '/workspace-settings',
  TRIAL_SAAS :'/trial-saas',
  LOGIN_BY_EMAIL: '/signin',
  VERIFY_EMAIL: '/verify-email',
  CHANGE_PASSWORD: '/change-password',
  INVOICES: "/invoices",
  BASE_DASHBOARD_NEW_WORKSPACE : "/dashboard/new-workspace/:token",
  BASE_DASHBOARD_VIEW: "/dashboard/my-workspace/:token",
  LOGOUT :"/logout"
}